import classNames from "classnames";
import styles from "./Skeleton.module.scss";

export type SkeletonProps = {
  width: string;
  height: string;
  customClassName?: string;
};

export const Skeleton = ({ width, height, customClassName }: SkeletonProps) => {
  return (
    <div
      className={classNames(styles.container, customClassName)}
      style={{ width: `${width}`, height: `${height}` }}
    ></div>
  );
};
