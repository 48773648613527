import { useState } from "react";
import styles from "./CheckBox.module.scss";
import classNames from "classnames";

export type CheckBoxProps = {
  id: string;
  value?: string;
  checked: boolean;
  disabled?: boolean;
  onChangeFunction: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  onlyLabel?: boolean;
  isEmpty?: boolean;
};

export const CheckBox = ({
  id,
  value,
  checked,
  disabled,
  onChangeFunction,
  onlyLabel,
  isEmpty,
  children,
}: CheckBoxProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);
    onChangeFunction(e);
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.onlyLabel]: onlyLabel,
        [styles.similLink]: isEmpty,
      })}
    >
      <label className={styles.label}>
        <input
          id={id}
          name={value || id}
          type="checkbox"
          value={value}
          checked={isChecked}
          disabled={disabled}
          className={styles.checkbox}
          onChange={(e) => handleChange(e)}
        />
        <span className={styles.checkmark}></span>
        {children && children}
      </label>
    </div>
  );
};
