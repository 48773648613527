import styles from "./SecondStepTableRow.module.scss";
import { Feature, Software } from "@/types/software";
import { labels } from "@/utils/labels";
import React from "react";
import { TableRow } from "@/components/table/components/tableRow/TableRow";
import { TableCell } from "@/components/table/components/tableCell/TableCell";
import { CheckBox } from "@/components/checkbox/CheckBox";

export type SecondStepTableRowProps = {
  serie?: Software;
  feature?: Feature;
  id: number | string;
  rowIdToString: string;
  handleOnClick: Function;
};

export const SecondStepTableRow = ({
  serie,
  feature,
  id,
  rowIdToString,
  handleOnClick,
}: SecondStepTableRowProps) => {
  return (
    <React.Fragment key={serie ? serie.softwareId : feature?.recipeId}>
      <TableRow>
        <TableCell size={20} hasBorderRight>
          <p
            className={`${styles.series} ${styles.text}`}
            title={serie ? serie.softwareName : feature?.featureName}
          >
            {serie ? serie.softwareName : feature?.featureName}
          </p>
        </TableCell>
        <TableCell size={serie ? 50 : 65} hasBorderRight>
          <p
            className={`${styles.description} ${styles.text}`}
            title={
              serie ? serie.softwareDescription : feature?.featureDescription
            }
          >
            {serie ? serie.softwareDescription : feature?.featureDescription}
          </p>
        </TableCell>
        {serie && (
          <TableCell size={15} hasBorderRight>
            <p
              className={`${styles.version} ${styles.text}`}
              title={serie.softwareVersion}
            >
              {serie.softwareVersion}
            </p>
          </TableCell>
        )}
        <TableCell size={15}>
          {serie && serie.suggested ? (
            <p className={styles.btnChecked}>{labels.INCLUDED_DEFAULT}</p>
          ) : (
            <CheckBox
              id={rowIdToString}
              checked={serie ? serie.isChecked! : feature!.isChecked!}
              onChangeFunction={() => handleOnClick(id)}
              isEmpty
            >
              {(serie && serie.isChecked) || (feature && feature.isChecked)
                ? `${labels.REMOVE}`
                : `${labels.ADD_TO_SDK}`}
            </CheckBox>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
