import { Box, Tabs } from "@radix-ui/themes";
import styles from "./SecondStepTable.module.scss";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SecondStepTableTabs } from "./components/SecondStepTableTabs/SecondStepTableTabs";
import {
  getActiveTab,
  getFeatureList,
  getSelectedRow,
  getSelectedSoftwareCube1,
  getSelectedSoftwareCube2,
  getSoftwareList,
} from "@/utils/selectors";
import { useGetSoftwareListQuery } from "@/pages/api/apiSlice";
import { Software } from "@/types/software";
import { ErrorRow } from "@/components/errorRow/ErrorRow";
import {
  addSoftware,
  setFeatureList,
  setSoftwareList,
  toggleBuild,
  toggleFeature,
  toggleSoftware,
} from "@/features/software/softwareSlice";
import { labels } from "@/utils/labels";
import { ApiError, ButtonType } from "@/types/types";

import { SecondStepTableRow } from "./components/SecondStepTableRow/SecondStepTableRow";
import { Table } from "@/components/table/Table";
import { TableRow } from "@/components/table/components/tableRow/TableRow";
import { TableCell } from "@/components/table/components/tableCell/TableCell";
import React from "react";
import { Skeleton } from "@/components/skeleton/Skeleton";
import { Button } from "@/components/button/Button";

export type SecondStepTableProps = {
  myProps?: boolean;
};

export const SecondStepTable = ({ myProps }: SecondStepTableProps) => {
  const selectedRow = useSelector(getSelectedRow);
  const selectedSoftwareCube1 = useSelector(getSelectedSoftwareCube1);
  const softwareList = useSelector(getSoftwareList);
  const featureList = useSelector(getFeatureList);
  const selectedSoftwareCube2 = useSelector(getSelectedSoftwareCube2);
  const activeTab = useSelector(getActiveTab);

  const dispatch = useDispatch();

  const {
    currentData: softwareData,
    isLoading,
    isFetching,
    isError,
    error: queryError,
  } = useGetSoftwareListQuery(`${selectedRow?.seriesId}`, {
    skip: selectedRow?.seriesId === undefined,
    refetchOnMountOrArgChange: true,
  });

  /**
   * @description calculate already inluded items of cube 1 list
   */
  const includedItems: Software[] | undefined = useMemo(() => {
    return softwareData?.softwareList
      .filter((item) => item.suggested === true)
      .map((item) => ({
        ...item,
        isChecked: true,
      }));
  }, [softwareData]);

  /**
   * @description when the user clicks on an element its parameter isChecked is set to true or false
   * and the list of selected items of cube 1 or cube 2 is updated
   * @param id of clicked item
   */
  const handleOnClick = (id: number) => {
    const clickedItemCube1 = softwareList.find(
      (item) => item.softwareId === id
    );
    const clickedItemCube2 = featureList.find((item) => item.recipeId === id);

    if (clickedItemCube1) {
      dispatch(toggleSoftware(clickedItemCube1));
      const updatedList =
        softwareList &&
        softwareList.map((item) => {
          if (item.softwareId === id) {
            return { ...item, isChecked: !item.isChecked };
          }
          return item;
        });

      dispatch(setSoftwareList(updatedList));
    }
    if (clickedItemCube2) {
      dispatch(toggleFeature(clickedItemCube2));

      const updatedList =
        featureList &&
        featureList.map((item) => {
          if (item.recipeId === id) {
            return { ...item, isChecked: !item.isChecked };
          }
          return item;
        });

      dispatch(setFeatureList(updatedList));
    }
  };

  /**
   * @description when user change tab the cube 1 or cube 2 lists are resetted
   */
  const handleChangeTab = useCallback(() => {
    if (!softwareData) return;

    const updatedSoftwareList = softwareData.softwareList.map((software) => ({
      ...software,
      isChecked: software.suggested ? true : false,
    }));

    const updatedFeatureList = softwareData.featureList.map((feature) => ({
      ...feature,
      isChecked: false,
    }));

    dispatch(setSoftwareList(updatedSoftwareList));
    dispatch(setFeatureList(updatedFeatureList));
  }, [softwareData]);

  /**
   * @description when softwareData populate redux state softwareList and featureList
   */
  useEffect(() => {
    if (softwareData) {
      if (includedItems) dispatch(addSoftware(includedItems));

      dispatch(
        setSoftwareList(
          softwareData.softwareList.map((software) => ({
            ...software,
            isChecked:
              software.suggested ||
              selectedSoftwareCube1.some(
                (item) => item.softwareId === software.softwareId
              ),
          }))
        )
      );

      dispatch(
        setFeatureList(
          softwareData.featureList.map((feature) => ({
            ...feature,
            isChecked: false,
          }))
        )
      );
    }
  }, [softwareData]);

  /**
   * @description handle error
   */
  if (isError) {
    if ("data" in queryError) {
      const errorDetails = queryError.data as ApiError;

      return <ErrorRow>{errorDetails.errorDescription}</ErrorRow>;
    }
  }

  if (isLoading || isFetching) {
    return (
      <div className={styles.skeletonWrapper}>
        <Skeleton width={"100%"} height={"34.5rem"} />
        <div className={styles.skeletonBtn}>
          <Skeleton width={"17.6rem"} height={"4rem"} />
        </div>
      </div>
    );
  }

  return (
    <>
      <Tabs.Root defaultValue="cube1">
        {featureList && featureList.length !== 0 && (
          <SecondStepTableTabs handleChangeTab={handleChangeTab} />
        )}
        <Box>
          <Tabs.Content value="cube1">
            <Table
              title={labels.SOFTWARE.toUpperCase()}
              customClassName={styles.table}
            >
              <TableRow>
                <TableCell size={20} isSubtitle>
                  {labels.NAME}
                </TableCell>
                <TableCell size={50} isSubtitle>
                  {labels.DESCRIPTION}
                </TableCell>
                <TableCell size={30} isSubtitle>
                  {labels.VERSION}
                </TableCell>
              </TableRow>

              {softwareList &&
                softwareList.map((serie, index) => {
                  const id = serie.softwareId;
                  const rowIdToString = id.toString();

                  return (
                    <React.Fragment key={id + "_" + index}>
                      <SecondStepTableRow
                        serie={serie}
                        id={id + "_" + index}
                        rowIdToString={rowIdToString}
                        handleOnClick={() => handleOnClick(id)}
                      />
                    </React.Fragment>
                  );
                })}
            </Table>
          </Tabs.Content>

          <Tabs.Content value="cube2">
            <Table title={labels.FEATURES.toUpperCase()}>
              <TableRow>
                <TableCell size={20} isSubtitle>
                  {labels.NAME}
                </TableCell>
                <TableCell size={80} isSubtitle>
                  {labels.DESCRIPTION}
                </TableCell>
              </TableRow>

              {featureList &&
                featureList.map((feature, index) => {
                  const id = feature.recipeId;
                  const rowIdToString = id.toString();

                  return (
                    <React.Fragment key={id + "_" + index}>
                      <SecondStepTableRow
                        feature={feature}
                        id={id}
                        rowIdToString={rowIdToString}
                        handleOnClick={() => handleOnClick(id)}
                      />
                    </React.Fragment>
                  );
                })}
            </Table>
          </Tabs.Content>
        </Box>
      </Tabs.Root>
      <div className={styles.btnContainer}>
        <Button
          onClick={() => dispatch(toggleBuild())}
          type={ButtonType.PRIMARY}
          buttonText={labels.VALIDATE_SELECTION}
          disabled={activeTab === 2 && selectedSoftwareCube2.length === 0}
          ariaLabel={`${labels.VALIDATE_SELECTION} action`}
        />
      </div>
    </>
  );
};
