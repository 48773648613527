import styles from "./Step.module.scss";
import classnames from "classnames";
import { Search } from "@/components/search/Search";
import { labels } from "@/utils/labels";
import { Button } from "../button/Button";
import { IconNames } from "@/types/iconNames";
import { ButtonType } from "@/types/types";
import { downloadFile } from "@/utils/function";
import { useSelector } from "react-redux";
import { getAcceptLicense } from "@/utils/selectors";

export type StepProps = {
  title: string;
  index: number;
  hasSearch?: boolean;
  children?: React.ReactNode;
  isActive?: boolean;
  hasTitleMarginBottom?: boolean;
  packCounter?: number | undefined;
  hasDownloadPdfBtn?: boolean;
};

export const Step = ({
  title,
  index,
  hasSearch,
  children,
  isActive,
  hasTitleMarginBottom,
  packCounter,
  hasDownloadPdfBtn,
}: StepProps) => {
  const acceptLicense = useSelector(getAcceptLicense);

  return (
    <section
      className={classnames(styles.container, { [styles.active]: isActive })}
    >
      <div
        className={classnames(styles.titleContainer, {
          [styles.marginBottom]: hasTitleMarginBottom,
        })}
      >
        <div className={styles.titleBox}>
          <span
            className={classnames(styles.index, { [styles.active]: isActive })}
          >
            {index}
          </span>
          <h2
            className={classnames(styles.title, { [styles.active]: isActive })}
          >
            {title}
          </h2>
        </div>
        {/* counter */}
        {!isActive && !!packCounter && packCounter !== 0 && (
          <p className={styles.counter}>{`${packCounter} ${
            packCounter > 1 ? `${labels.PACKS}` : `${labels.PACK}`
          } selected`}</p>
        )}
        {/* search */}
        {hasSearch && <Search containerClassname={styles.search} />}
        {/* download PDF */}
        {!isActive && hasDownloadPdfBtn && acceptLicense && (
          <span className={styles.btnContainer}>
            <Button
              buttonText={labels.DOWNLOAD_PDF}
              iconName={IconNames.DOWNLOAD}
              iconColor="var(--color-secondary)"
              type={ButtonType.LINK}
              onClick={() =>
                downloadFile(
                  "licensepdf/1",
                  "SDK_software_license_agreement.pdf"
                )
              }
              ariaLabel="Download PDF action"
            />
          </span>
        )}
      </div>
      {children && <>{children}</>}
    </section>
  );
};
