import { IconNames } from "@/types/iconNames";
import { makeIconName } from "@/utils/function";
import Icon from "@/icon/Icon";
import styles from "./TableHeader.module.scss";

export type TableHeaderProps = {
  icon?: string;
  headerTitle: string;
};

export const TableHeader = ({ icon, headerTitle }: TableHeaderProps) => {
  return (
    <thead className={styles.head}>
      <tr className={styles.row}>
        {icon && (
          <th className={styles.imageContainer}>
            <Icon
              iconName={IconNames[makeIconName(icon) as keyof typeof IconNames]}
              color={"var(--color-neutral-01"}
              height={18}
              width={18}
            />
          </th>
        )}
        <th className={styles.title}>{headerTitle}</th>
      </tr>
    </thead>
  );
};
