import { RootProps } from "@radix-ui/themes/dist/cjs/components/dialog";
import styles from "./Dialog.module.scss";
import * as RadixDialog from "@radix-ui/react-dialog";
import Icon from "@/icon/Icon";
import { IconNames } from "@/types/iconNames";
import { labels } from "@/utils/labels";
import classNames from "classnames";

export enum DialogType {
  BASIC = "basic",
  GENERAL_SDK = "general",
}

export type DialogProps = RootProps & {
  title?: string;
  children: React.ReactNode;
  hasCancelBtn?: boolean;
  type?: DialogType;
  hasCloseBtn?: boolean;
  closeBtnAction: Function;
};

export const Dialog = ({
  title,
  children,
  hasCancelBtn,
  type = DialogType.BASIC,
  hasCloseBtn,
  closeBtnAction,
  ...props
}: DialogProps) => {
  return (
    <RadixDialog.Root
      defaultOpen={false}
      open={props.open}
      onOpenChange={props.onOpenChange}
    >
      <RadixDialog.Portal>
        <RadixDialog.Overlay className={styles.overlay} />
        <RadixDialog.Content
          className={classNames(styles.content, {
            [styles.basicContent]: type === DialogType.BASIC,
            [styles.generalContent]: type === DialogType.GENERAL_SDK,
          })}
        >
          <div className={styles.headerWrapper}>
            {title && (
              <RadixDialog.Title className={styles.title}>
                {title}
              </RadixDialog.Title>
            )}
            {hasCloseBtn && (
              <RadixDialog.Close asChild>
                <button
                  type="button"
                  className={styles.closeBtn}
                  aria-label="Close action"
                  onClick={() => closeBtnAction()}
                >
                  <Icon
                    iconName={IconNames.CLOSE}
                    color={
                      type === DialogType.BASIC
                        ? "var(--color-primary)"
                        : "var(--color-neutral-01"
                    }
                  />
                </button>
              </RadixDialog.Close>
            )}
          </div>
          {children}
          {hasCancelBtn && (
            <RadixDialog.Close asChild>
              <button
                type="button"
                className={styles.cancelBtn}
                aria-label="Cancel action"
              >
                {labels.CANCEL}
              </button>
            </RadixDialog.Close>
          )}
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};
