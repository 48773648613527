import {
  getActiveTab,
  getSelectedSoftwareCube1,
  getSelectedSoftwareCube2,
  getSoftwareList,
} from "@/utils/selectors";
import styles from "./SecondStep.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Step } from "@/components/step/Step";
import { labels } from "@/utils/labels";
import { SecondStepTable } from "@/components/stepContent/secondStep/secondStepTable/SecondStepTable";

export type SecondStepProps = {
  isActive: boolean;
};

export const SecondStep = ({ isActive }: SecondStepProps) => {
  const selectedSoftwareCube1 = useSelector(getSelectedSoftwareCube1);
  const selectedSoftwareCube2 = useSelector(getSelectedSoftwareCube2);
  const activeTab = useSelector(getActiveTab);

  return (
    <Step
      index={2}
      title={labels.STEP2_TITLE}
      isActive={isActive}
      packCounter={
        activeTab === 1
          ? selectedSoftwareCube1?.length
          : selectedSoftwareCube2?.length
      }
    >
      {isActive && (
        <>
          <p className={styles.cubeParagraph}>{labels.STEP2_PARAGRAPH}</p>
          <SecondStepTable />
        </>
      )}
    </Step>
  );
};
