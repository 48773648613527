import Icon from "@/components/icon/Icon";
import styles from "./SidebarItem.module.scss";
import { IconNames } from "@/types/iconNames";
import classNames from "classnames";

export type SidebarItemProps = {
  text: string;
  isHighlighted?: boolean;
};

export const SidebarItem = ({ text, isHighlighted }: SidebarItemProps) => {
  return (
    <div
      className={classNames(styles.sidebarItem, {
        [styles.highlighted]: isHighlighted,
      })}
    >
      <p className={styles.iconText}>
        <span
          className={classNames(styles.icon, styles.check, {
            [styles.active]: isHighlighted,
          })}
        >
          <Icon
            iconName={IconNames.CHECK}
            color={"var(--color-neutral-01)"}
            width={14}
            height={14}
          />
        </span>
        <span className={styles.text}>{text}</span>
      </p>
      <span className={styles.icon}>
        <Icon
          iconName={IconNames.ARROW_RIGHT}
          color={"var(--color-primary)"}
          height={20}
          width={20}
        />
      </span>
    </div>
  );
};
